import {
    LOAD_LIST_BANK, LOAD_LIST_BANK_TRANSFER, LOAD_EXCHANGE_RATE,
    LOAD_LIST_AGENCY, SET_WITH_DRAW, LOAD_ALL_AGENCY, LOAD_LIST_BANK_USER,
    CANCEL_BANK_TRANSFER, FORGOT_PASSWORD
} from '../../constants/ActionTypes';

import { fetchApi } from "../../utils/api";
export const getAllBank = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/list-bank`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadListBank(response.data));
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
};


export const getBanks = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/list-bank-deposits`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadListBank(response.data));
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
};

export function loadListBank(payload) {
    return {
        type: LOAD_LIST_BANK,
        payload
    };
}

// WithDraw Money
// get list bank user
export const getBankUser = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/user/get-list-bank`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadListBankUser(response.data));
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
};
// post request withdraw money
export const requestWithDrawMoney = async (payload, dispatch) => {
    // let total = ;
    try {
        let response = await fetchApi(`/app/payment/withdraw-money`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export function loadListBankUser(payload) {
    return {
        type: LOAD_LIST_BANK_USER,
        payload
    };
}

// BankTransfer

export const getListBankTransfer = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/get-bank-transfer`, 'get', payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadListBankTransfer(response.data));
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
};
export function loadListBankTransfer(payload) {
    return {
        type: LOAD_LIST_BANK_TRANSFER,
        payload
    };
}

// END BANK TRANSFER //////////////

export const requestAddMoney = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/bank-transfer`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const calcelAddMoney = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/cancel-bank-transfer`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const submitPaid = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/confirm-bank-transfer`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const addBank = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/user/add-bank`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export const getExchangeRate = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/get-exchange-rate`, "get");
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadExchangeRate(response.data));
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};




export function loadExchangeRate(payload) {
    return {
        type: LOAD_EXCHANGE_RATE,
        payload
    };
}

export function loadListAgency(payload) {
    return {
        type: LOAD_LIST_AGENCY,
        payload
    };
}

export const getListAgency = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/list-agency`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        await dispatch(loadListAgency(response.data.rows));
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export function loadAllAgency(payload) {
    return {
        type: LOAD_ALL_AGENCY,
        payload
    };
}

export const getAllAgency = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/list-all-agency`, "get", payload);
        if (response.code !== 200) {
            return [];
        }
        await dispatch(loadAllAgency(response.data));
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};



export const swapMoneyGold = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/swap-money-gold`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const swapGoldMoney = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/swap-gold-money`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};
export const requestWithdrawGold = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/request-withdraw-gold`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export function setWithdraw(payload) {
    return {
        type: SET_WITH_DRAW,
        payload
    };
}

// cancel-bank-transfer
export const CancelBankTransfer = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/payment/cancel-bank-transfer`, "post", payload);
        return response;
    } catch (error) {
        return {
            message: "Có lỗi sảy ra, vui lòng thử lại sau",
            signal: 0
        };
    }
};

export function cancelWithdraw(payload) {
    return {
        type: CANCEL_BANK_TRANSFER,
        payload
    };
}



